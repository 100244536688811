@charset "UTF-8";
/* @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css'); */

body {
	font-family: 'Poppins', sans-serif;
	font-weight: normal;
	color: #383838;
	font-size: 16px;
	line-height: 24px;
	font-weight: normal;
	overflow-x: hidden;
	background-color: #ffffff;
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	min-height: 100%;font-family: 'Sora', sans-serif;
}

article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
	display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Poppins', sans-serif;
}

ul,
li {
	margin: 0;
	padding: 0;
}

a {
	font-family: 'Poppins', sans-serif;
	font-weight: normal;
	font-size: 16px;
	color: #3081CC;
	text-decoration: none;
	-webkit-transition: all 0.5s ease-out;
	-moz-transition: all 0.5s ease-out;
	-ms-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

a:hover {
	text-decoration: underline;
}

a:focus {
	outline: none;
	text-decoration: none;
}

img {
	border: 0 none;
}

p {
	margin: 0 0 15px 0;
}



.logo img {width: 100%; height: auto; aspect-ratio: auto;}
header {
	padding: 25px;
}
header ul.menu {list-style: none;text-align: center;}
header ul.menu li {
	text-align: center;
    display: inline;
    padding: 0 20px;
	position: relative;
	top:4px;
}
header ul.menu li a {
    color: grey;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
}
header ul.menu li a:hover {text-decoration: none;}
header ul.menu li a.active {
	color: #288DDD;
    border-bottom: 2px solid #288DDD;
    padding-bottom: 5px;
}
header ul.menu2 {
    list-style: none;
    display: inline;
}
header ul.menu2 li {    
	text-align: center;
    display: inline;
    padding: 0 15px;
}
header ul.menu2 li a {
	color: grey;
}
header .searchBar {
    display: inline;
	position: relative;
    margin-left: 30px;
}
header .searchBar input {
    border: 1px solid #bababa;
    border-radius: 4px;
    padding: 5px 10px;
    border-radius: 0 6px 6px 0;width: 290px;
}
header .searchBar button {
	position: absolute;
	right:0;
	top:-6;
    background: #288DDD;
    border: 0;
    padding: 5px 8px;
    border-radius: 0 6px 6px 0;
}
header .searchBar img {}
.seperator {border-right: 2px solid #818181;}
.bold {font-weight: 600;}
.slider {
	background-image:url(../img/slider_caseList.png);
	background-size:cover;
	height:500px;
	width: 100%;
}
.h-100 {
	height: 100%;
}
.slider h1 {
	font-size:72px;
	color:white;
	font-weight:bold;
    padding-top: 60px;
}
.slider .about h1 {
	text-align: center;
    padding-top: 180px;
}
.slider h2 {
	font-size:40px;
	color:white;
	padding-bottom: 15px;
}
.slider .dropdown-select {
	padding:15px 10px;
	border-radius:10px;
}
form {
	margin-top:60px
}
.datePick {padding: 15px;width: 100%;margin-top: 20px;border-radius: 6px;}
.css-13cymwt-control,
.css-t3ipsp-control {padding: 10px;}
.searchForm {
	margin-top:20px;
	border:0;
	border-radius:3px;
	background: #288DDD;
	padding: 15px;
	width: 100%;
	color: white;
	font-size: 20px;
}


.table {margin-top:50px}
.table thead {border-left:0;border-right:0;}
.table thead tr {}
/* .table tbody tr:nth-child(2n+2){
	background-color: rgb(238, 238, 238);
} */
.table thead tr th {font-weight:500;font-size: 14px;}
.table tbody {}
.table tbody tr {font-size:14px;}
.table tbody tr th {}
.table tbody tr td {}
.table tbody tr td div {}
.table tbody tr td div h1 {font-size:14px;color:#288DDD}
.table tbody tr td div p {margin:0;padding:0;}
.table tbody tr td div p span {font-weight:600;margin-right:7px;}
.table tbody img {width:100px}

.table-footer {
    /* background: rgb(238, 238, 238); */
    text-align: center;
    padding: 10px;
	border: 1px solid rgb(220, 220, 220);
}
.table-footer ul {
	list-style: none;
}
.table-footer ul li {
    display: inline;
    padding: 5px;
    color: black;
    font-weight: 500;
    cursor: pointer;
}
.table-footer ul li:hover {
	text-decoration: underline;
}
.table-footer ul li.paginationActive a {font-weight: bold;}
.table-footer ul li a {
	color: black;
}


.attorney {
	background-image:url(../img/attorney.png);
	background-size:cover;
	/* height:500px; */
	width: 100%;
	margin-top:50px;
	padding: 50px;
}
.attorney h1 {
	color:white;text-align: center;font-size:25px
}
.attorney input {
	width: 100%;
    background: transparent;
    border: 0;
    border-bottom: 2px solid #288DDD;
	padding: 20px 10px;
    color: #97B7D1;
}
.attorney input::placeholder, textarea::placeholder {
	color:#97B7D1;
}
.attorney label {
	width: 100%;
    background: transparent;
    border: 0;
    /* border-bottom: 2px solid #288DDD; */
    padding: 10px;
    color: #97B7D1;
}
.attorney textarea {    width: 100%;
    background: transparent;
    border: 0;
    border-bottom: 2px solid #288DDD;resize: none;
    padding: 10px;
    color: #97B7D1;}
.attorney button {
	background-color: #288DDD;
	padding: 10px 20px;
    border: 0;
    border-radius: 5px;
	color:white;
	left:50%;
	position:relative;
	transform:translateX(-50%);
	margin-top:20px
}
.attorney-disclaimer-text {
    font-size: 13px;
    padding: 0px 10px;
    color: #97B7D1;
}
.react-datepicker-wrapper:after {
	content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    top: 45%;
    right: 10px;
    background: url('./../img/calendar.png');    
	background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.react-datepicker-wrapper {position: relative;}
footer {
	background-color: #133857;
	padding: 20px;
}
footer h1 {
	font-size: 14px;
	color:white;
	font-weight: 300;
	margin-top: 20px;
}
footer ul {list-style: none;display: flex;}
footer ul li {
	text-align: center;
    display: inline;
    padding: 0 20px;
	position: relative;
	top:4px;
}
footer ul li a {
    color: grey;
}
footer .icon {width: 55px;border:1px solid white;border-radius:5px;padding:7px}
footer .icon img {
	width: 100%;
}


.breadcrumb {}
.breadcrumb ul {list-style: none;display: flex;}
.breadcrumb ul li {padding-right: 30px;}
.breadcrumb ul li a.active {color:blue}
.breadcrumb ul li a {}


.about-content {}
.about-content h1 {font-size: 26px;}
.about-content .table-about {
	width: 100%;
	border-bottom: 1px solid #dcdcdc;
	padding: 10px;
}
.about-content .table-about tbody {}
.about-content .table-about tbody tr {
	border-top: 1px solid #dcdcdc;
}
.about-content .table-about thead tr th,
.about-content .table-about tbody tr td {
	padding: 10px;
	vertical-align: baseline;
	/* padding-left:20px; */
}
.about-content .table-about thead tr th {
	font-weight: 600;
	font-size: 14px;
}
.about-content .table-about tbody tr img {
	width: 100px;
    position: relative;
    left: 0%;
    /* transform: translateX(-50%); */
}

.about-content .table-about tbody tr td:first-child,
.about-content .table-about thead tr th:first-child {
	width: 200px;
	/* border-right: 1px solid rgb(193, 193, 193); */
	font-weight: 600;
	padding-left:20px;
	font-size: 14px;
}

.bordered th,
.bordered td {
	/* border: 1px solid rgb(193, 193, 193) */
}
.bordered .align-cent {
	width: 190px;
	text-align:left;
}
.loading-case {
	color: #3081CC;
	margin-top: 20px;
}

.paginationActive {
	color: #333 !important;
}
.searchBarForm {
	display: inline !important;
	margin-top: 0 !important;
}
.wd-cst {width: 130px;}



.loaderBlocker {
	display: none;
}
.loaderBlocker .fadeOut {
	background-color: rgba(0, 0, 0, 0.552);
	width: 100%;
	height: 100%;
	z-index: 1;
	position: fixed;
	top: 0;
	left: 0;
	cursor: wait;
}
.loader {
    border: 10px dotted #ffffff;
    border-radius: 50%;
    border-top: 10px solid #00000000;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
    position: fixed;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    transform: translate(-50%,-50%);
    z-index: 2;
}

button > span > p, a > span > p {
	margin: 0px;
	display: inline;
}
  
  /* Safari */
  @-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }
  /*  Media */
  /* @media (max-width: 1400px) and (min-width: 1024px) {
	header .searchBar input {
		width: auto;
	}
  } */
  @media only screen and (min-width: 1200px) {
  .col-md-7.forcustomdiv {
    justify-content: end;
    display: flex;
	align-items: center;
}
}
@media only screen and (max-width: 1024px) {
	header ul.menu li {
		padding: 0 4px;
	}
	.slider:has(.about) {
		height: 222px;
	}
	.slider h1 {
		font-size: 48px;
		padding-top: 30px;
	}
	.slider h2 {
		line-height: 1rem;
		padding-bottom: 1px;
	}
	.slider form {
		margin-top: 20px;
	}
	header .searchBar {
		margin-left: 10px;
	}
	header .searchBar input {width: 189px;
		font-size: 10px;}
	header ul.menu2 li, header ul.menu2 li a {
		font-size: 14px;
	}
	.slider {
		height: 288px;
	}
	.slider .about h1 {
    padding-top: 80px;
}
	footer .icon {
    width: 47px;
    padding: 3px;
}
footer ul li {
    padding: 0 10px;
}
}
@media (max-width:991px) {
	header ul.menu li{padding: 0 1px;}
	.slider:has(.about) {
		height: 116px!important;
	}
	button.logoutBtn {
    margin-left: 0;
    margin-top: 15px;
    width: 100%;
}
header .searchBar input {
    width: 98%;
}
	.slider .about h1 {
    padding-top: 35px;
}
	.attorney {
    padding-right: 10px;
    padding-left: 10px;
}
	.tableContent {
    overflow-x: auto;
}
	.slider {
    height: auto;
    padding-bottom: 20px;
}
	h2.edit-h2.setContent {
    font-size: 30px;
    padding-bottom: 0px;
}
	h2.edit-h2.setContent p {
    margin-bottom: 0px;
}
	h2.edit-h2.setContent {
    font-size: 30px;
}
	.slider form {
    margin-top: 40px;
}
	.edit-icon.editJs.mt-5 {
    margin-top: 1rem!important;
}
	.slider form {
    margin-top: 20px;
}
.slider h1 {
    font-size: 41px;
    padding-top: 39px;
}
header .searchBar {
    margin-left: 0;
}
header {
    padding: 30px 10px;
}
header ul.menu {
    margin-top: 0px;
    margin-bottom: 30px;
}
header ul.menu li a {
    font-size: 14px;
}
header ul.menu2 {
    margin-bottom: 20px;
    display: block;
	text-align: center;
}

.attorney input,.attorney label {
    font-size: 13px;
}
.slider h2 {
    font-size: 36px;
}
.tableContent > .table {
	min-height: 1800px;
}
}

@media only screen and (max-width: 767px) {
	.bannerTextArea {
		min-height: 280px;
	}
	.about-content h1 {
		font-size: 18px;
	}
	.goodServ {
		font-size: 13px;
	}
	footer ul {
		justify-content: center;
	}
	.slider:has(.about) {
		height: 116px!important;
	}
	button.logoutBtn {
    margin-left: 0;
    margin-top: 15px;
    width: 100%;
}
header .searchBar input {
    width: 98%;
}
	.slider .about h1 {
    padding-top: 35px;
}
	.attorney {
    padding-right: 10px;
    padding-left: 10px;
}
	.tableContent {
    overflow-x: auto;
}
	.slider {
    height: auto;
    padding-bottom: 20px;
}
	h2.edit-h2.setContent {
    font-size: 30px;
    padding-bottom: 0px;
}
	h2.edit-h2.setContent p {
    margin-bottom: 0px;
}
	h2.edit-h2.setContent {
    font-size: 30px;
}
	.slider form {
    margin-top: 40px;
}
	.edit-icon.editJs.mt-5 {
    margin-top: 1rem!important;
}
	.slider form {
    margin-top: 20px;
}

.slider h1 {
    font-size: 41px;
    padding-top: 39px;
}
header .searchBar {
    margin-left: 0;
}
header {
    padding: 30px 10px;
}
header ul.menu {
    margin-top: 15px;
    margin-bottom: 27px;
}
header ul.menu2 {
    margin-bottom: 20px;
    display: block;
	text-align: center;
}

.footerBx p {
    text-align: center;
}
.attorney input,.attorney label {
    font-size: 13px;
}
header ul.menu li a {
    font-size: 17px;
}
}
.exportButton {
	color:#3081CC;
	border: none;
	background:none;
	font-size: 16px;
	padding: 0px;
	font-family: 'Poppins', sans-serif;
}
.exportButton:hover {
	text-decoration: underline;
}
footer .terms-of-use-link {
	text-decoration: none !important;
    color: #ffffff !important;
    font-family: Poppins,sans-serif;
    font-size: 14px;
    font-weight: 300;
}
footer .terms-of-use-link:hover {
	text-decoration: underline;
}

/**
	Captcha CSS
*/
.captcha input[type=text] {
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
 
canvas{
  /*prevent interaction with the canvas*/
  pointer-events:none;
}


 
.react-confirm-alert-body {
	width: 100% !important;
    color: #666;
}

.react-confirm-alert-body > h1 {
    font-size: 25px !important;
}

.react-confirm-alert-button-group > button {
    background: #288DDD !important;
    font-size: 13px;
}