@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Black.eot');
  src: url('../fonts/Poppins-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-Black.woff2') format('woff2'),
      url('../fonts/Poppins-Black.woff') format('woff'),
      url('../fonts/Poppins-Black.ttf') format('truetype'),
      url('../fonts/Poppins-Black.svg#Poppins-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Bold.eot');
  src: url('../fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-Bold.woff2') format('woff2'),
      url('../fonts/Poppins-Bold.woff') format('woff'),
      url('../fonts/Poppins-Bold.ttf') format('truetype'),
      url('../fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-BlackItalic.eot');
  src: url('../fonts/Poppins-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-BlackItalic.woff2') format('woff2'),
      url('../fonts/Poppins-BlackItalic.woff') format('woff'),
      url('../fonts/Poppins-BlackItalic.ttf') format('truetype'),
      url('../fonts/Poppins-BlackItalic.svg#Poppins-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-BoldItalic.eot');
  src: url('../fonts/Poppins-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-BoldItalic.woff2') format('woff2'),
      url('../fonts/Poppins-BoldItalic.woff') format('woff'),
      url('../fonts/Poppins-BoldItalic.ttf') format('truetype'),
      url('../fonts/Poppins-BoldItalic.svg#Poppins-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraBold.eot');
  src: url('../fonts/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-ExtraBold.woff2') format('woff2'),
      url('../fonts/Poppins-ExtraBold.woff') format('woff'),
      url('../fonts/Poppins-ExtraBold.ttf') format('truetype'),
      url('../fonts/Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraLight.eot');
  src: url('../fonts/Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-ExtraLight.woff2') format('woff2'),
      url('../fonts/Poppins-ExtraLight.woff') format('woff'),
      url('../fonts/Poppins-ExtraLight.ttf') format('truetype'),
      url('../fonts/Poppins-ExtraLight.svg#Poppins-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraBoldItalic.eot');
  src: url('../fonts/Poppins-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-ExtraBoldItalic.woff2') format('woff2'),
      url('../fonts/Poppins-ExtraBoldItalic.woff') format('woff'),
      url('../fonts/Poppins-ExtraBoldItalic.ttf') format('truetype'),
      url('../fonts/Poppins-ExtraBoldItalic.svg#Poppins-ExtraBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Medium.eot');
  src: url('../fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-Medium.woff2') format('woff2'),
      url('../fonts/Poppins-Medium.woff') format('woff'),
      url('../fonts/Poppins-Medium.ttf') format('truetype'),
      url('../fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Light.eot');
  src: url('../fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-Light.woff2') format('woff2'),
      url('../fonts/Poppins-Light.woff') format('woff'),
      url('../fonts/Poppins-Light.ttf') format('truetype'),
      url('../fonts/Poppins-Light.svg#Poppins-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-LightItalic.eot');
  src: url('../fonts/Poppins-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-LightItalic.woff2') format('woff2'),
      url('../fonts/Poppins-LightItalic.woff') format('woff'),
      url('../fonts/Poppins-LightItalic.ttf') format('truetype'),
      url('../fonts/Poppins-LightItalic.svg#Poppins-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Italic.eot');
  src: url('../fonts/Poppins-Italic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-Italic.woff2') format('woff2'),
      url('../fonts/Poppins-Italic.woff') format('woff'),
      url('../fonts/Poppins-Italic.ttf') format('truetype'),
      url('../fonts/Poppins-Italic.svg#Poppins-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraLightItalic.eot');
  src: url('../fonts/Poppins-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-ExtraLightItalic.woff2') format('woff2'),
      url('../fonts/Poppins-ExtraLightItalic.woff') format('woff'),
      url('../fonts/Poppins-ExtraLightItalic.ttf') format('truetype'),
      url('../fonts/Poppins-ExtraLightItalic.svg#Poppins-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-MediumItalic.eot');
  src: url('../fonts/Poppins-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-MediumItalic.woff2') format('woff2'),
      url('../fonts/Poppins-MediumItalic.woff') format('woff'),
      url('../fonts/Poppins-MediumItalic.ttf') format('truetype'),
      url('../fonts/Poppins-MediumItalic.svg#Poppins-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Regular.eot');
  src: url('../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-Regular.woff2') format('woff2'),
      url('../fonts/Poppins-Regular.woff') format('woff'),
      url('../fonts/Poppins-Regular.ttf') format('truetype'),
      url('../fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Thin.eot');
  src: url('../fonts/Poppins-Thin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-Thin.woff2') format('woff2'),
      url('../fonts/Poppins-Thin.woff') format('woff'),
      url('../fonts/Poppins-Thin.ttf') format('truetype'),
      url('../fonts/Poppins-Thin.svg#Poppins-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ThinItalic.eot');
  src: url('../fonts/Poppins-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-ThinItalic.woff2') format('woff2'),
      url('../fonts/Poppins-ThinItalic.woff') format('woff'),
      url('../fonts/Poppins-ThinItalic.ttf') format('truetype'),
      url('../fonts/Poppins-ThinItalic.svg#Poppins-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-SemiBold.eot');
  src: url('../fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
      url('../fonts/Poppins-SemiBold.woff') format('woff'),
      url('../fonts/Poppins-SemiBold.ttf') format('truetype'),
      url('../fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-SemiBoldItalic.eot');
  src: url('../fonts/Poppins-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
      url('../fonts/Poppins-SemiBoldItalic.woff') format('woff'),
      url('../fonts/Poppins-SemiBoldItalic.ttf') format('truetype'),
      url('../fonts/Poppins-SemiBoldItalic.svg#Poppins-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}


